// Wait for document to load before adding sidebar
document.addEventListener("DOMContentLoaded", () => {
  // Select the sidebar div
  const sidebar = document.querySelector("#sidebar");
  // Add the sidebar contents to the div
  sidebar.innerHTML = `
    <div id="btn">
          <div id="navMenu">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <header>MASONS.CODES</header>
        <ul>
          <li>
            <a href="/"
              ><i></i
              ><img class="icon" src="${require("/src/resources/icon-home.svg")}" />Home</a
            >
          </li>
          <li>
            <a href="/resume"
              ><i></i
              ><img class="icon" src="${require("/src/resources/icon-resume.svg")}" />Resume</a
            >
          </li>
          <li>
            <a href="/projects"
              ><i></i
              ><img
                class="icon"
                src="${require("/src/resources/icon-project.svg")}"
              />Projects</a
            >
          </li>
          <li>
            <a href="/faq"
              ><i></i><img class="icon" 
              src="${require("/src/resources/icon-faq.svg")}" />FAQ</a
            >
          </li>
          <li>
            <a href="/contact"
              ><i></i
              ><img class="icon" 
src="${require("/src/resources/icon-contact.svg")}" />Contact</a
            >
          </li>
        </ul>
  `;
  // Select the button and navMenu divs
  const btn = document.querySelector("#btn");
  const navMenu = document.querySelector("#navMenu");
  // Add listeners to toggle class on button click
  btn.addEventListener("click", () => {
    btn.classList.toggle("active");
    navMenu.classList.toggle("active");
    sidebar.classList.toggle("active");
  });
});
